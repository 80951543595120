/**
 * @module StickyNav
 * @description Global Sticky Nav
 */
import {
  useState,
  useEffect,
  useContext,
  useRef
} from 'react';
import PropTypes from 'prop-types';
import Link from 'next/link';
import { useAgentStatus } from '@/hooks/useAgentStatus';
import { stringify } from '@/js/utils/filters';
import { FuelContext } from '@/providers/fuel-provider';
import { getCookie } from '@/js/utils/cookie';
import { trackEvent } from '@/js/helpers/tracking';
import styles from './index.module.css';
import { formatPhoneNumber } from '@/js/helpers/fuse';
import useMediaQuery from '@/hooks/useMediaQuery';
import { DEFAULT_FUSE_NUMBER } from '@/js/constants/monarch';


/**
 * @function Component
 * @description
 */
const Component = ( { ...props } ) => {
  const {
    copy = 'Ready to checkout? Order online in minutes.',
    mobileCta = 'Call now',
    mobileCtaColor = 'white',
    phoneNumberEnabled = true,
    isDesktopNav = false,
    customStyles
  } = props;
  const [ isOpen, setIsOpen ] = useState( null );
  const [ isCustomer, setIsCustomer ] = useState( null );
  const [ isPageHydrated, setIsPageHydrated ] = useState( false );
  const [ amexOffer, setAmexOffer ] = useState( false );
  const [ revealBanner, setRevealBanner ] = useState( false );
  const {
    clickThrough, cartHeavy, openHours,
    fuseNumber
  } = useContext( FuelContext );
  const fetchStatus = useAgentStatus( );

  const isMobile = useMediaQuery( '(max-width: 1024px)' );

  const complementaryLinks = [
    { href: '/', title: 'Residential' }
  ];

  const menus = [
    {
      title: 'Business',
      links: [
        { href: 'https://business.frontier.com/?kbid=80875', title: 'Small Business' },
        { href: 'https://enterprise.frontier.com/', title: 'Enterprise' },
        { href: 'https://wholesale.frontier.com/home', title: 'Wholesale' },
        { href: '/resources/multifamily', title: 'Multifamily' }
      ]
    }
  ];

  const phoneProps = useRef( { } );
  const phoneNumber = fuseNumber || DEFAULT_FUSE_NUMBER;

  /**
   * @function handleClick
   * @description handle tagular tracking
   */
  const handleClick = ( event ) => {
    const { target } = event;
    const { text, actionOutcome } = JSON.parse( target.getAttribute( 'data-payload' ) );
    const data = {
      elementType: 'BUTTON',
      location: 'GLOBAL',
      position: 'HEADER',
      text,
      actionOutcome
    };

    trackEvent( { action: 'elementClicked', data, event } );
  };

  /**
   * @function initSticky
   * @description Begin sticky nav
   */
  const initSticky = () => {
    const site = document.querySelector( '.site' );
    const navbar = document.querySelector( '[data-sticky-nav]' );

    if ( ! navbar ) {
      return;
    }

    const sticky = navbar.clientHeight;

    if ( window.pageYOffset >= sticky ) {
      navbar.classList.add( 'sticky' );
      site.style.scrollPadding = `${ navbar.clientHeight }px`;
    } else {
      navbar.classList.remove( 'sticky' );
      site.style.scrollPadding = '0px';
    }
  };

  /**
   * @function useEffect
   * @description Kick off this modules functions
   */
  useEffect( () => {
    // When the user scrolls the page, execute initSticky
    window.onscroll = () => initSticky();

    // Only want to display our Fuse number to non-existing customers
    if ( getCookie( 'frontieramp' ) ) {
      setIsCustomer( true );
    } else {
      setIsCustomer( false );
    }

    // if we're in an amexOffer, adjust phone number
    if ( getCookie( 'isAmexOffer' ) ) {
      phoneProps.current = { href: 'tel:877.837.6222' };
      setAmexOffer( true );
    }

    // will return true / false
    if ( openHours.length ) setIsOpen( fetchStatus( openHours ) );
  }, [ fetchStatus, openHours ] );

  useEffect( () => {
    if ( isOpen != null && isCustomer != null ) {
      setRevealBanner( true );
    }
  }, [ isOpen, isCustomer ] );

  useEffect( () => {
    if ( isPageHydrated === false ) {
      setIsPageHydrated( true );
    }
  }, [ isPageHydrated ] );

  return <>
    <section { ...( isMobile && isDesktopNav && { style: { display: 'none' } } ) }
      className={
        `${ styles.component }
         ${ customStyles && customStyles.component }`
        }
         data-is-open={ isOpen ? 'true' : 'false' }
        >
      <div className="l-container">
        <div className={ styles.component__content }>
          <nav className={ `navigation ${ styles.component__navigation }` } aria-label="Sticky Navigation">
            <ul className="menu">
              { complementaryLinks.map( ( link, index ) => (
                  <li key={ index } className={ index === 0 ? styles.stickNav__liActive : '' }>
                    <Link
                      href={ link.href }
                      onClick={ handleClick }
                      data-payload={ stringify( {
                        text: link.title,
                        actionOutcome: 'INTERNALLINK'
                      } ) }>
                      { link.title }
                    </Link>
                  </li>
              ) ) }

              { menus.map( ( menu, index ) => (
                    <li key={ index } className={ `menu-item ${ menu.links.length ? 'menu-item-has-children' : '' }` }>

                    { menu.links.length ?
                      <>
                        <button
                          className={ `btn ${ styles.component__button }` }
                          type="button"
                          onClick={ handleClick }
                          data-payload={ stringify( {
                            text: menu.title,
                            actionOutcome: 'INTERALLINK'
                          } ) }
                          role="button">{ menu.title }
                          <span className={ `icon icon-chevron-down ${ styles.component__iconChevron } ` } />
                        </button>
                      </> :
                      <>
                        <Link
                          href={ menu.href }
                          target={ menu.target }
                          onClick={ handleClick }
                          data-payload={ stringify( {
                            text: menu.title,
                            position: 'NAVIGATION',
                            actionOutcome: 'INTERALLINK'
                          } ) }>
                          { menu.title }
                        </Link>
                      </>
                    }

                    {/*
                      Wait for the page to be hydrated before allowing any submenus to render.
                      Since this submenu is controlled by CSS, waiting for hydration prevents
                      the issue of seeing a flash of an open submenu while the page loads.
                    */}
                    { isPageHydrated &&
                      <div className={ `submenu-wrap ${ styles.component__submenuWrap }` }>
                        <ul className="submenu menu">
                          { menu.links.map( ( link, index1 ) => (
                              <li key={ index1 }>
                                <Link
                                  href={ link.href }
                                  target={ link.target }
                                  onClick={ handleClick }
                                  data-payload={ stringify( {
                                    text: link.title,
                                    position: 'NAVIGATION',
                                    actionOutcome: 'INTERALLINK'
                                  } ) }>
                                  { link.title }
                                </Link>

                                { link.links &&
                                  <ul>
                                    { link.links.map( ( link1, index2 ) => (
                                        <li key={ index2 }>
                                          <Link
                                            href={ link1.href }
                                            target={ link1.target }
                                            onClick={ handleClick }
                                            data-payload={ stringify( {
                                              text: link1.title,
                                              position: 'NAVIGATION',
                                              actionOutcome: 'INTERALLINK'
                                            } ) }>
                                            { link1.title }
                                          </Link>
                                        </li>
                                    ) ) }
                                  </ul>
                                }
                              </li>
                          ) ) } { /* <!-- .menu-item --> */ }
                        </ul>
                      </div>
                    }
                    </li>
              ) ) }
            </ul>
          </nav>

          { revealBanner === true &&
            <>
              { ! isCustomer ?
                <>
                  { isOpen ?
                    <>
                      <p>{ copy }</p>
                      { ( cartHeavy === false ) &&
                        <p { ...( phoneNumberEnabled === false && { style: { visibility: 'hidden' } } ) }// Hide phone number without adjusting position of other list items
                           className={
                            `${ customStyles && customStyles.component }
                             ${ customStyles && customStyles.component__phone }
                             ${ styles.component__phone }
                            ` }>
                          <Link
                            href={ `tel:${ phoneNumber }` }
                            { ...phoneProps.current }
                            onClick={ handleClick }
                            data-payload={ stringify( {
                              text: 'Phone Number',
                              actionOutcome: 'CLICKTOCALL'
                            } ) }>
                            {/* phone number is shortened on mobile */}
                            <span className={ isMobile ? `u-color-${ mobileCtaColor }` : '' }>{ isMobile ? mobileCta : 'Call now' }</span>
                            <span className={ `icon icon-phone-outline ${ styles.component__phoneIcon } ${ customStyles && customStyles.component__phoneIcon }` } />
                            <em>{ amexOffer ? ' 1-877-837-6222' : ` ${ formatPhoneNumber( phoneNumber, '-', isMobile ) }` }</em>
                          </Link>
                        </p>
                      }
                    </> :
                    <>
                      <div className={ `${ styles.component__phone } ${ styles.component__phoneClosed }` }>
                        <p>Find exclusive deals near you!</p>
                        <Link
                          href={ clickThrough }
                          onClick={ handleClick }
                          className="btn btn-small"
                          data-payload={ stringify( {
                            text: 'Shop Now',
                            actionOutcome: 'INTERNALLINK'
                          } ) }>
                          Shop now
                        </Link>
                      </div>
                    </>
              }
                </> :
                null
              }
            </>
          }
        </div>
      </div>
    </section>{ /* <!-- .sticky-nav --> */ }
  </>;
};

Component.propTypes = {
  copy: PropTypes.string,
  phoneNumberEnabled: PropTypes.bool,
  isDesktopNav: PropTypes.bool
};

export default Component;
